import IaaiEventBus from "@/events";
import {AUCTION_EVENT_IAAI, IAAI_EVENT_AUCTION_JOINED, IAAI_EVENT_LEAVE_AUCTION_CONFIRMATION} from "@/consts";
import store from "@/store";
import {SET_CONNECTED_CLIENT, SET_ERROR_CONNECTED} from "@/store/mutations.type";
import * as Sentry from "@sentry/vue";
const AuctionNow = require('./assets/js/AuctionNow')

class IaaiService {

    constructor() {
        this.iaaiEventBus = null;
        this.iaaiClient = null;
    }

    /**
     *
     * @param {string} bearerToken
     */
    init(bearerToken) {
        if(bearerToken) {

            const configuration = {
                // prod values
                "portalApiUrl": process.env.VUE_APP_PORTAL_API_URL,
                "systemHubUrl": process.env.VUE_APP_SYSTEM_HUB_URL,
                // test values
                // "portalApiUrl": process.env.VUE_APP_PORTAL_API_URL_TEST,
                // "systemHubUrl": process.env.VUE_APP_SYSTEM_HUB_URL_TEST,
                "bearerToken": bearerToken,
            }

            this.iaaiClient = new AuctionNow.Client(configuration);
            const iaaiEventBusInit = new IaaiEventBus
            this.iaaiEventBus = iaaiEventBusInit.init(this.iaaiClient, this)
        } else {
            store.commit(SET_CONNECTED_CLIENT, false);
            store.commit(SET_ERROR_CONNECTED, 'An error has occurred. Try again.');
        }
    }

    /**
     * join to auction
     *
     * @param {string} InternalAuctionId
     * @param {string} ExternalAuctionId
     * @param {function} successCallback
     * @param {function} failCallback
     */
    joinToAuction(InternalAuctionId, ExternalAuctionId, successCallback, failCallback) {
        /**
         * event subscription
         */
        this.iaaiEventBus.$on(AUCTION_EVENT_IAAI + InternalAuctionId, async function (payload) {
            switch (payload.iaaiEvent) {
                case IAAI_EVENT_AUCTION_JOINED:
                    successCallback(false)
                    break
                case IAAI_EVENT_LEAVE_AUCTION_CONFIRMATION:
                    successCallback(true)
                    break
                default:
                    failCallback(new Error("Unexpected iaai event: " + payload.iaaiEvent))
                    break
            }
        });

        this.iaaiClient.join("iaai", ExternalAuctionId);
    }

    /**
     *
     * @param {string} InternalAuctionId
     * @param {function} successCallback
     */
    leaveAuction(InternalAuctionId, successCallback) {
        this.iaaiClient.leave(InternalAuctionId)
            .then(function () {
                successCallback();
            })
            .catch(function (error) {
                console.log('error', error)
            })
    }

    /**
     * Checks if the bid amount is within spending limits
     * @param {number} amount - Bid amount to check
     * @returns {{isValid: boolean, errorMessage: string, moneyLimit: number, carsLimit: number}} - Validation result
     * @private
     */
    _checkSpendingLimits(amount) {
        const storageLimit = store.state.auctions.spendingLimit;
        const cookieName = 'sold_cars_info';

        // Get data from cookie
        let soldCars = [];
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith(cookieName + '='));

        if (cookieValue) {
            soldCars = JSON.parse(cookieValue.split('=')[1]);
        }

        // Calculate money limit
        let moneyLimit = storageLimit;
        if (soldCars.length > 0) {
            const spentMoney = soldCars.reduce((sum, car) => sum + car.price, 0);
            moneyLimit = storageLimit - spentMoney;
        }

        // Calculate cars limit
        let maxCarsLimit;

        if (soldCars.length === 0 && storageLimit > 0) {
            maxCarsLimit = 1;
        } else {
            maxCarsLimit = Math.floor(storageLimit / 6600);
        }

        // Limit maximum number of cars to 20
        maxCarsLimit = Math.min(maxCarsLimit, 20);
        const carsLimit = soldCars.length > 0 ? maxCarsLimit - soldCars.length : maxCarsLimit;

        // Check limits and form error message
        let errorMessage = '';

        if (moneyLimit < amount && carsLimit <= 0) {
            errorMessage = 'Both money limit and cars limit have been reached';
        } else if (moneyLimit < amount) {
            errorMessage = 'Money spending limit has been reached';
        } else if (carsLimit <= 0) {
            errorMessage = 'Cars limit has been reached';
        }

        return {
            isValid: !errorMessage,
            errorMessage,
            moneyLimit,
            carsLimit
        };
    }

    /**
     * Sends limit exceeded event to Sentry
     * @param {string} methodName - Name of the method where limit was exceeded
     * @param {object} validation - Validation result object
     * @param {object} params - Additional parameters
     * @private
     */
    _sendLimitExceededEvent(methodName, validation, params) {
        Sentry.captureMessage(`Spending limit exceeded in ${methodName}`, {
            level: "warning",
            extra: {
                moneyLimit: validation.moneyLimit,
                carsLimit: validation.carsLimit,
                requestedAmount: params.amount,
                userId: params.userId,
                auctionId: params.auctionId,
                itemId: params.itemId,
                errorMessage: validation.errorMessage
            }
        });
    }

    /**
     * Places a bid for an auction item
     * @param {string} InternalAuctionId - Auction identifier
     * @param {string} InternalAuctionItemId - Item identifier in the auction
     * @param {number} amount - Bid amount
     * @param {string} userId - User identifier
     * @returns {Promise} - Returns a promise that resolves when the bid is placed or rejects with an error
     */
    placeBid(InternalAuctionId, InternalAuctionItemId, amount, userId) {
        const validation = this._checkSpendingLimits(amount);

        if (!validation.isValid) {
            // Send event to Sentry
            this._sendLimitExceededEvent('placeBid', validation, {
                amount,
                userId,
                auctionId: InternalAuctionId,
                itemId: InternalAuctionItemId
            });

            store.commit('SET_BIDDING_ERROR', {
                InternalAuctionId,
                InternalAuctionItemId,
                BiddingError: validation.errorMessage
            });
            return Promise.reject(new Error(validation.errorMessage));
        }

        return this.iaaiClient.placeBid(InternalAuctionId, InternalAuctionItemId, amount, userId);
    }

    /**
     * Places an auto bid for an auction item
     * @param {string} InternalAuctionId - Auction identifier
     * @param {string} InternalAuctionItemId - Item identifier in the auction
     * @param {number} amount - Auto bid amount
     * @param {string} userId - User identifier
     * @returns {Promise} - Returns a promise that resolves when the auto bid is placed or rejects with an error
     */
    placeAutoBid(InternalAuctionId, InternalAuctionItemId, amount, userId) {
        const validation = this._checkSpendingLimits(amount);

        if (!validation.isValid) {
            // Send event to Sentry
            this._sendLimitExceededEvent('placeAutoBid', validation, {
                amount,
                userId,
                auctionId: InternalAuctionId,
                itemId: InternalAuctionItemId
            });

            store.commit('SET_BIDDING_ERROR', {
                InternalAuctionId,
                InternalAuctionItemId,
                BiddingError: validation.errorMessage
            });
            return Promise.reject(new Error(validation.errorMessage));
        }

        return this.iaaiClient.placeAutoBid(InternalAuctionId, InternalAuctionItemId, amount, userId);
    }

    /**
     * Modifies an existing auto bid for an auction item
     * @param {string} InternalAuctionId - Auction identifier
     * @param {string} InternalAuctionItemId - Item identifier in the auction
     * @param {number} amount - New auto bid amount
     * @param {string} userId - User identifier
     * @returns {Promise} - Returns a promise that resolves when the auto bid is modified or rejects with an error
     */
    modifyAutoBid(InternalAuctionId, InternalAuctionItemId, amount, userId) {
        const validation = this._checkSpendingLimits(amount);

        if (!validation.isValid) {
            // Send event to Sentry
            this._sendLimitExceededEvent('modifyAutoBid', validation, {
                amount,
                userId,
                auctionId: InternalAuctionId,
                itemId: InternalAuctionItemId
            });

            store.commit('SET_BIDDING_ERROR', {
                InternalAuctionId,
                InternalAuctionItemId,
                BiddingError: validation.errorMessage
            });
            return Promise.reject(new Error(validation.errorMessage));
        }

        return this.iaaiClient.modifyAutoBid(InternalAuctionId, InternalAuctionItemId, amount, userId);
    }
}

export default IaaiService
