import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from "vue-router";
import IaaiService from "@/IaaiService";
import * as Sentry from "@sentry/vue";

const jquery = require('../node_modules/jquery/dist/jquery.min')
window.$ = window.jQuery = jquery

const signalr = require('../node_modules/signalr/jquery.signalR.min');
window.signalr = signalr

Vue.config.productionTip = false;

const routes = [
    {path: '/', component: App}
]

const router = new VueRouter({
    mode: 'history',
    routes
})

Vue.use(VueRouter)

Vue.prototype.$iaaiService = new IaaiService()

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

// Initialize Sentry
Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    beforeSend(event) {
        // Don't send events from localhost:8000
        if (window.location.origin === 'http://localhost:8000') {
            return null;
        }

        // Check if the stack trace contains references to AuctionNow.js
        if (event.exception && event.exception.values) {
            for (const exception of event.exception.values) {
                if (exception.stacktrace && exception.stacktrace.frames) {
                    for (const frame of exception.stacktrace.frames) {
                        if (frame.filename && frame.filename.includes('AuctionNow.js')) {
                            // Ignore error from AuctionNow.js
                            return null;
                        }
                    }
                }
            }
        }

        return event;
    }
});

new Vue({
    store,
    router,
    render: h => h(App),
    beforeCreate() {
        const bearerToken = this.$route.query.token || getCookie('token');
        this.$iaaiService.init(bearerToken);
        document.cookie = `token=${bearerToken}`;
    }
}).$mount('#app')
